<template>
  <main id="content" role="main" class="overflow-hidden">
    <!-- header -->
    <!-- <header id="header" class="header header-bg-transparent-lg">
      <div class="header-section" style="background-color: #f5f9fc;">
        <div id="logoAndNav" class="container">
          <nav class="js-mega-menu navbar navbar-expand-lg pt-lg-4">
            <router-link :to="{ path: '/' }" class="navbar-brand" aria-label="apidays.hk">
              <img src="@/assets/img/apidays-2023/images/apidays-2023-logo.png" alt="Logo">
            </router-link>
          </nav>
        </div>
      </div>
    </header> -->

    <div class="position-relative z-index-2 overflow-hidden space-top-1" style="background-color: #f5f9fc;">
      <div class="container mt-md-3 mb-md-3 position-relative space-top-2">

        <!-- Content -->
        <div class="w-lg-90 mx-auto">
          <div class="card">
            <div class="card-body">
              <!-- <h2 class="text-center">Join Our Lucky Draw and Win an iPad at Apidays Connect Hong Kong 2023!</h2> -->
              <img class="img-fluid mb-6" src="@/assets/img/apidays-2023/images/luck-draw-banner.jpg">

              <div class="my-6">
                <h3 class="mb-4">Result Announcement — Congratulations to Our Winners!</h3>
                <div class="full-width-youtube-video mb-4">
                  <iframe src="https://www.youtube.com/embed/ngNhLaNTFaE?si=QQEp8eEnVJlWi2wF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
                </div>

                <p>Thank you for everyone's support for Apidays Connect Hong Kong 2023! We will be sending an email to our winners with instructions on how to collect your prizes. Please remember to monitor your mailbox carefully and look out for our email.</p>
              </div>
              <div class="my-6">
                <h3>Terms and Conditions</h3>
                <ol class="small">
                  <li>The lucky draw is open to all guests attending the event.</li>
                  <li>Each guest is eligible for one entry into the lucky draw.</li>
                  <li>Two lucky winners will be selected to receive iPads.</li>
                  <li>To qualify for the lucky draw, guests must gather stamps from all six booths and register at the registration desk.</li>
                  <li>The winners will be announced on the official website <a href="https://apidays.hk">apidays.hk</a> on 6th September, 2023.</li>
                  <li>The winners will also be notified via email with the contact information provided during the registration process.</li>
                  <li>If a winner fails to respond within the given time, the event organizers reserve the right to select an alternate winner or cancel the lucky draw at their discretion.</li>
                  <li>The prizes are non-transferable and cannot be exchanged for cash or any other items.</li>
                  <li>The event organizers reserve the right to modify or cancel the lucky draw or these terms and conditions at any time without prior notice.</li>
                  <li>Participation in the lucky draw constitutes acceptance of these terms and conditions.</li>
                </ol>
              </div>
            </div>
          </div>

        </div>

      </div></div></main>
</template>

<script>
import '../../../../assets/js/hs.core.js'
import 'bootstrap'

export default {
  name: 'Blog1',
  computed: {
  },
  metaInfo() {
    return {
      title: 'Lucky Draw | Apidays Connect Hong Kong 2023',
      titleTemplate: 'Lucky Draw | Apidays Connect Hong Kong 2023',
      link: [
        { rel: 'canonical', href: 'https://www.apidays.hk/2023/lucky-draw' }
      ],
      meta: [
        { name: 'description', content: 'Lucky Draw | Apidays Connect Hong Kong 2023' },
        { property: 'og:site_name', content: 'Lucky Draw | Apidays Connect Hong Kong 2023' },
        { property: 'og:title', content: 'Apidays Connect Hong Kong 2023' },
        { property: 'og:description', content: '30-31 Aug, 2023 - The API Landscape of Hong Kong | apidays Connect Hong Kong 2023 is going to bring together leading API experts both local and international to share their seasonal insights and lessons learnt in driving the team and business under the "new normal".' },
        { property: 'og:image', content: 'https://www.apidays.hk/assets/img/thumbnails/apidays-hongkong-2023.jpg' },
        { property: 'og:url', content: 'https://www.apidays.hk/' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
.step-icon {
  font-size: 18px;
}
.timeline-heading {
  font-size: 18px;
}
ol {
  list-style: block;
  padding-inline-start: 40px;
}

.full-width-youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.full-width-youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
